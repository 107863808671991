
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogRT () {
    return _createElement('div', {
        'className': 'dialog-container',
        'role': 'dialog'
    }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close',
        'role': 'button'
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, this.name === 'vehicle-dialog' ? [_createElement('div', {
            'className': 'dialog-body',
            'key': '2670'
        }, [this.DialogVehicleWidget(function () {
                function repeatSelects1(selects, selectsIndex) {
                    return [selects(function () {
                            function repeatEntry1(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                            }
                            function repeatEntry2(popularEntries, unpopularEntries, isPopularField, entry, entryIndex) {
                                return _createElement('option', {
                                    'disabled': this.loading,
                                    'value': entry.term,
                                    'key': entry.value
                                }, '\n          ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n        ');
                            }
                            function scopePopularEntriesUnpopularEntriesIsPopularField3() {
                                var popularEntries = this.entries.filter(entry => entry.payload === 'Popular');
                                var unpopularEntries = this.entries.filter(entry => entry.payload !== 'Popular');
                                var isPopularField = popularEntries.length && unpopularEntries.length;
                                return [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          Select ', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    isPopularField ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '10651'
                                        }, 'Popular ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values'),
                                        _map(popularEntries, repeatEntry1.bind(this, popularEntries, unpopularEntries, isPopularField)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '10654'
                                        }, 'Additional ', this.title === 'Make' ? 'Makes' : this.title === 'Model' ? 'Models' : 'Values')
                                    ] : null,
                                    _map(isPopularField ? unpopularEntries : this.entries, repeatEntry2.bind(this, popularEntries, unpopularEntries, isPopularField))
                                ];
                            }
                            function scopeShowSelectedValues4() {
                                var showSelectedValues = this.selectedEntries.length;
                                return _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, showSelectedValues ? [this.selectedEntries.map(e => e.value).join(', ')] : null, !showSelectedValues ? [
                                    'Select ',
                                    this.title
                                ] : null);
                            }
                            function repeatEntry5(entry, index) {
                                return _createElement('div', {
                                    'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                    'key': entry.value,
                                    'onClick': () => this.onChange(entry.term)
                                }, this.showCheckboxes ? _createElement('input', {
                                    'type': 'checkbox',
                                    'readOnly': true,
                                    'checked': entry.selected,
                                    'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                    'key': '3414'
                                }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                    'className': 'cm_dropdown_hitCount',
                                    'key': '3616'
                                }, entry.hitCount) : null);
                            }
                            return _createElement('div', { 'className': 'form-field ' + this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                'key': '49'
                            }, _createElement('select', {
                                'className': 'cm_select_inner-select form-select',
                                'onChange': this.onChange,
                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                'disabled': this.disabled,
                                'aria-label': this.title
                            }, this.useNativeDropdown ? scopePopularEntriesUnpopularEntriesIsPopularField3.apply(this, []) : null, !this.useNativeDropdown ? scopeShowSelectedValues4.apply(this, []) : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                        return _createElement('div', { 'className': 'form-input cm_filterInput' });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                    'aria-label': this.title,
                                    'key': '2717'
                                },
                                !this.hideNullOption ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'onClick': () => this.onChange('')
                                }, '\n      Select ', this.title, '\n    ') : null,
                                this.loading ? _createElement('div', {
                                    'className': 'option',
                                    'key': 'null-option',
                                    'disabled': true
                                }, '...loading...') : null,
                                !this.loading ? _map(this.entries, repeatEntry5.bind(this)) : null
                            ]) : null);
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_model-selector dialog cm_DialogVehicleWidget' }, _createElement('div', { 'className': 'active-container themevale_MultiCategory' }, _createElement('div', { 'className': 'themevale_multilevel-category-filter' }, _createElement('div', { 'className': 'model-selector-body form-wrapper' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'selects-container form-wrapper cmRepeater_selects' },
                    _map(this.selects, repeatSelects1.bind(this))
                ]), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
                    'className': 'cm_button button button--primary',
                    'onClick': this.selectVehicle,
                    'disabled': !this.allowToSetVehicle
                }, '\n    GO\n  '), _createElement('div', {
                    'className': 'cm_button button button--primary',
                    'id': 'themevale_clear-select',
                    'title': 'Reset',
                    'onClick': this.discardVehicle,
                    'disabled': !this.allowToDiscardVehicle
                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-refresh' }))))))));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null, this.name === 'facet-dialog' ? [
        _createElement('div', {
            'className': 'dialog-title',
            'key': '78390'
        }, 'Filters'),
        [this.FacetPanel(function () {
                return _createElement('div', { 'className': 'cm_facet-panel__full-width dialog-body cm_FacetPanel' }, [this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    return this.template === 'chip' ? _createElement('li', { 'className': 'cmTemplate_chip' }, _createElement('a', { 'className': 'facetLabel' }, '\n  ', this.value, '\n  ', _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' })))) : this.template === 'rangeChip' ? _createElement('li', { 'className': 'cmTemplate_rangeChip' }, _createElement('a', { 'className': 'facetLabel' }, this.finite ? [
                                        this.selectedRange[0],
                                        ' to ',
                                        this.selectedRange[1]
                                    ] : null, this.toPosInf ? [
                                        this.selectedRange[0],
                                        ' and more'
                                    ] : null, this.toNegInf ? [
                                        this.selectedRange[1],
                                        ' and less'
                                    ] : null, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' })))) : this.template === 'reviewChip' ? _createElement('li', { 'className': 'cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        { 'className': 'facetLabel' },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '299' }, ' and up') : null,
                                        _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))
                                    ])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'facetedSearch-refineFilters sidebarBlock cm_filterChips' }, _createElement('div', { 'className': 'sidebarBlock-heading facettitle' }, 'Refine by'), _createElement.apply(this, [
                            'ul',
                            { 'className': 'inlineList inlineList--labels facetbody cmRepeater_chips' },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('a', {
                            'className': 'cm_btn',
                            'data-cm-role': 'start-over'
                        }, 'Clear all'));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })], [this.facets(function () {
                        function repeatFacets1(facets, facetsIndex) {
                            return [facets(function () {
                                    function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                return _createElement('li', { 'className': 'navList-item' + (this.treeLevel > 0 ? ` cm_padding-${ this.treeLevel }` : '') }, _createElement('a', { 'className': 'navList-action navList-action--checkbox' + (this.isSelected ? ' is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, this.value, ' (', this.hitCount, ')'), this.isSelected ? _createElement('span', {
                                                    'className': 'navList-action-close',
                                                    'aria-hidden': 'true',
                                                    'key': '323'
                                                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues2(Values, ValuesIndex) {
                                        return [Values(function () {
                                                return _createElement('li', { 'className': 'navList-item' + (this.treeLevel > 0 ? ` cm_padding-${ this.treeLevel }` : '') }, _createElement('a', { 'className': 'navList-action navList-action--checkbox' + (this.isSelected ? ' is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, this.value, ' (', this.hitCount, ')'), this.isSelected ? _createElement('span', {
                                                    'className': 'navList-action-close',
                                                    'aria-hidden': 'true',
                                                    'key': '323'
                                                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                            }, { count: undefined })];
                                    }
                                    function repeatShowAlwaysValues3(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                        return [ShowAlwaysValues(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('li', { 'className': 'navList-item' }, _createElement('a', { 'className': 'navList-action navList-action--checkbox' + (this.isSelected ? ' is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '491'
                                                }, ' and up') : null, '\n         (', this.hitCount, ')'), this.isSelected ? _createElement('span', {
                                                    'className': 'navList-action-close',
                                                    'aria-hidden': 'true',
                                                    'key': '625'
                                                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                            }, { count: undefined })];
                                    }
                                    function repeatValues4(Values, ValuesIndex) {
                                        return [Values(function () {
                                                function scopeStarFill1(i, iIndex) {
                                                    var starFill = this.value - i;
                                                    return _createElement('span', {
                                                        'key': i,
                                                        'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                    });
                                                }
                                                function repeatI2(i, iIndex, starFill) {
                                                    return scopeStarFill1.apply(this, [
                                                        i,
                                                        iIndex
                                                    ]);
                                                }
                                                return _createElement('li', { 'className': 'navList-item' }, _createElement('a', { 'className': 'navList-action navList-action--checkbox' + (this.isSelected ? ' is-selected' : '') }, _createElement('span', { 'className': 'navList-action-value' }, _createElement.apply(this, [
                                                    'span',
                                                    { 'className': 'cm_review-stars' },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2.bind(this))
                                                ]), this.value !== '5' ? _createElement('span', {
                                                    'className': 'cm_and-up',
                                                    'key': '491'
                                                }, ' and up') : null, '\n         (', this.hitCount, ')'), this.isSelected ? _createElement('span', {
                                                    'className': 'navList-action-close',
                                                    'aria-hidden': 'true',
                                                    'key': '625'
                                                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-close' }))) : null));
                                            }, { count: undefined })];
                                    }
                                    return _createElement('div', { 'className': 'sidebarBlock' }, _createElement('div', {
                                        'className': 'facettitle sidebarBlock-heading',
                                        'data-cm-role': 'toggle-facet'
                                    }, this.name, ' \n    ', this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-plus-minus',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 448 512',
                                            'key': '1240'
                                        }, _createElement('path', { 'd': 'M416,208H272V64c0-17.67-14.33-32-32-32h-32c-17.67,0-32,14.33-32,32v144H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h144v144c0,17.67,14.33,32,32,32h32c17.67,0,32-14.33,32-32V304h144c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-plus-minus',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 448 512',
                                            'key': '5280'
                                        }, _createElement('path', { 'd': 'M416,208H32c-17.67,0-32,14.33-32,32v32c0,17.67,14.33,32,32,32h384c17.67,0,32-14.33,32-32v-32c0-17.67-14.33-32-32-32z' }))] : null), _createElement('div', {
                                        'className': 'facetbody',
                                        'role': 'list'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'sidebarBlock-content cmTemplate_simpleFacet',
                                        'key': '858'
                                    }, this.showFilterInput ? _createElement('div', {
                                        'className': 'filter-input',
                                        'key': '965'
                                    }, [this.filterInput(function () {
                                            return _createElement('div', {
                                                'className': 'input form-input cm_filterInput',
                                                'placeholder': 'Enter'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], this.inputNotEmpty ? _createElement('span', {
                                        'className': 'filter-input_clear-container',
                                        'onClick': this.clearInput,
                                        'key': '1295'
                                    }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '14641'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'ul',
                                        { 'className': 'navList cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'ul',
                                        { 'className': 'navList cmRepeater_Values' },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('div', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3865'
                                    }, _createElement('a', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more'
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'role': 'list',
                                        'className': 'sidebarBlock-content cmTemplate_reviewFacet',
                                        'key': '4178'
                                    }, _createElement.apply(this, [
                                        'ul',
                                        { 'className': 'navList cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues3.bind(this))
                                    ]), _createElement.apply(this, [
                                        'ul',
                                        { 'className': 'navList cmRepeater_Values' },
                                        _map(this.Values, repeatValues4.bind(this))
                                    ])) : null));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'facetedSearch-navList blocker-container cm_facets' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'accordion--navList cmRepeater_facets' },
                            _map(this.facets, repeatFacets1.bind(this))
                        ]));
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('div', {
            'className': 'dialog-footer',
            'key': '78394'
        }, _createElement('div', { 'className': 'dialog-footer-content' }, [this.showResultsButton(function () {
                return _createElement('button', {
                    'type': 'button',
                    'disabled': !this.totalHits,
                    'className': 'button button--primary cm_showResultsButton'
                }, this.totalHits ? [
                    'Show ',
                    this.totalHits,
                    ' Results'
                ] : null, !this.totalHits ? ['No Results'] : null);
            }, {
                widgetName: 'undefined',
                items: undefined
            })]))
    ] : null));
}
        export const componentNames = ["cm:filterInput","cm:DialogVehicleWidget","cm:filterChips","cm:filterInput","cm:facets","cm:FacetPanel","cm:showResultsButton"]