const iIcon = (id) => `<i class="icon"><svg><use xlinkHref="#icon-${id}"/></svg></i>`;
const svgIcon = (id) => `<svg class="icon"><use xlinkHref="#icon-${id}"/></svg>`;
export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',

  FACET_PANEL_CLASS: 'page-sidebar',
  FACET_INPUT_CLASS: 'form-input',
  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed" template="icons/plus" />
    <rt-virtual rt-if="!this.isCollapsed" template="icons/minus" />`,
  CURRENT_SEARCH: 'Refine by',
  START_OVER: 'Clear all',

  STORE_VEHICLE_IN_GARAGE: `Store your vehicle in the garage ${iIcon('check')}`,
  GET_PRODUCTS_FOR_VEHICLE: `Get products for your vehicle ${iIcon('check')}`,
  FIND_PARTS_YOU_NEED: `Easily find the parts you need ${iIcon('check')}`,

  GARAGE_BUTTON_CLASS: 'navUser-action',
  GARAGE_DROPDOWN_CLASS: 'login-dropdown',
  GARAGE_ICON: svgIcon('garage'),
  GARAGE_ICON_CONTAINER_CLASS: 'navUser-icon',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',
  GARAGE_TITLE: '<span class="navUser-text">garage</span>',
  REMOVE_VEHICLE_ICON: iIcon('close'),
  SELECT_TITLE: 'Select {{ title }}',
  SELECTS_CONTAINER_CLASS: 'form-wrapper',
  SELECT_CONTAINER_CLASS: 'form-field',
  SELECT_CLASS: 'form-select',

  MINI_STORE_STRING: '',

  FITMENT_UNIVERSAL_TEXT: 'May require modification',

  SEARCH_BOX_CLASS: 'dropdown dropdown--quickSearch themevale_search',
  SEARCH_BOX_FORM_CLASS: 'form-field',
  SEARCH_BOX_PLACEHOLDER: 'Search for a product',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
};
