import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import TemplateMainContent from 'Stores/fpautoparts/Templates/MainContent.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTilestabs from 'Stores/_default-store/Templates/facetTiles/tabs.rt'
import TemplatefitmentSearchhomeFitmentSearchContainer from 'Stores/fpautoparts/Templates/fitmentSearch/homeFitmentSearchContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import TemplatefacetTileshierarchicalContainer from 'Stores/fpautoparts/Templates/facetTiles/hierarchicalContainer.rt'
import { SearchHeader } from 'Components'
import { FilterChips } from 'Components'
import { SearchResult } from 'Components'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/fpautoparts/Templates/product/relatedItems.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/fpautoparts/Templates/fitmentSearch/fitmentTable.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchvehicleDropdownsContainer from 'Stores/fpautoparts/Templates/fitmentSearch/vehicleDropdownsContainer.rt'
import TemplatefitmentSearchgenericMiniStoreVehicleLabel from 'Stores/fpautoparts/Templates/fitmentSearch/genericMiniStoreVehicleLabel.rt'
import TemplatefitmentSearchvehicleLabelContainer from 'Stores/fpautoparts/Templates/fitmentSearch/vehicleLabelContainer.rt'
import TemplatefitmentSearchcategoryVehicleWidget from 'Stores/fpautoparts/Templates/fitmentSearch/categoryVehicleWidget.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/fpautoparts/Templates/fitmentSearch/verifyFitment.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/fpautoparts/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
FacetTiles,
SearchBox,
SearchBoxDialogButton,
FacetPanel,
SearchHeader,
FilterChips,
SearchResult,
RelatedItems,
FitmentTable,
VehicleWidget,
VerifyFitment,
Garage,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'MainContent': TemplateMainContent,
'facetTiles/tabs': TemplatefacetTilestabs,
'fitmentSearch/homeFitmentSearchContainer': TemplatefitmentSearchhomeFitmentSearchContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'FacetPanelContainer': TemplateFacetPanelContainer,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'product/relatedItems': TemplateproductrelatedItems,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'fitmentSearch/vehicleDropdownsContainer': TemplatefitmentSearchvehicleDropdownsContainer,
'fitmentSearch/genericMiniStoreVehicleLabel': TemplatefitmentSearchgenericMiniStoreVehicleLabel,
'fitmentSearch/vehicleLabelContainer': TemplatefitmentSearchvehicleLabelContainer,
'fitmentSearch/categoryVehicleWidget': TemplatefitmentSearchcategoryVehicleWidget,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};