//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-679:_6816,_4940,_6600,_9580,_9824,_1036,_3512,_1956;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-679')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-679', "_6816,_4940,_6600,_9580,_9824,_1036,_3512,_1956");
        }
      }catch (ex) {
        console.error(ex);
      }